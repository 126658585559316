module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://cms.nextsailor.com/graphql","headers":{"Authorization":"Bearer be88361574f9e8b19fbe865b7bda74b9c938040997d6c3fabbb10616e03b32fabeccd0ff27b114e2c818333e70c4f4729d41be4ea5ec00b44de2dfc6c62bfc668a3ebf6bba98d3da2570cf033126a9e1cb012e7b5390be58525a24b48b4502cc3847aaa867a451323937521593aafada814c3b9e4b0fc2e5a519e21b33bcc234"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["1"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/logoColorDark.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8b418c1293615c3e8b1145dbcc48beb6"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
